import '../../common'
import './home.scss'
import './cta/cta';
import './cta-second/cta-second';

import { setMaskCtaPhone } from './cta/cta';
import { setMaskCtaPhoneSecond } from './cta-second/cta-second';

declare var Email: any;

$(() => { //executa quando termina de carregar a página
  addListenerToSendEmail();
  addListenerToSendEmailSecond();
  setMaskCtaPhone();
  setMaskCtaPhoneSecond();
})

function addListenerToSendEmail(){

  $("#first-submitCTA").click(() => {
    const name = $("#first-cta-name").val();
    // const email = $("[name=email]").val(); //[x] pegar atributo do HTML
    const email = $("#first-cta-email").val();
    const phone = $("#first-cta-phone").val();

    Email.send({
      Host : "smtp.gmail.com",
      Username : "painel@timo.com.br",
      Password : "svchqhartwuftvig",
      To : 'contato@deliverix.app.br',
      From : email,
      Subject : "CTA 1 site deliverix.app.br",
      Body : `nome: ${name} <br>telefone: ${phone}` //`permite colocar variáveis $var
    }).then(
        hideSpin
    );
    return false;
  });
}

function hideSpin() {
  alert("Enviado com sucesso! Aguarde nosso contato.");

  var x = document.getElementById("loaderId");
  x.style.opacity = '0';
}

function addListenerToSendEmailSecond(){
  $("#second-submitCTA").click(() => {
    const name = $("#second-cta-name").val();
    const email = $("#second-cta-email").val();
    const phone = $("#second-cta-phone").val();

    Email.send({
      Host : "smtp.gmail.com",
      Username : "painel@timo.com.br",
      Password : "svchqhartwuftvig",
      To : 'contato@deliverix.app.br',
      From : email,
      Subject : "CTA 2 site deliverix.app.br",
      Body : `nome: ${name} <br>telefone: ${phone}` //`permite colocar variáveis $var
    }).then(
        hideSpinSecond
    );
    return false;
  });
}

function hideSpinSecond() {
  alert("Enviado com sucesso! Aguarde nosso contato.");

  var x = document.getElementById("loaderIdSecond");
  x.style.opacity = '0';
}


