import Inputmask from "inputmask";

export function setMaskCtaPhoneSecond(): void {
  const inputMask = new Inputmask({
    clearIncomplete: true,
    mask: ["(99) 9999-9999", "(99) 9 9999-9999"],
  });

  inputMask.mask($("#second-cta-phone"));
}
