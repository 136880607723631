import './scss/app.scss';
import 'bootstrap';
import 'inputmask';


$(() => {
    console.log('page loaded');
    smoothScrolling();
})

function smoothScrolling(): void {
    $(".smooth-scrolling").on('click', function (e: any) {
        e.preventDefault();

        const hash = e.currentTarget.hash;

        $('html, body').animate(
            {scrollTop: $(hash).offset()!.top - 70},
            500,
            () => {
                if(history.pushState) {
                    history.pushState(null, '', hash);
                }
                else {
                    window.location.hash = hash;
                }
            }
        );

    });
}
